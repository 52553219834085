import Abstract from './abstract';
import {
  toggleClass, getBrowserHeight, rootElement, onSendGTag,
} from './module';

const INITIAL_STATE = {
  scrollTop: 0,
  isSideBarFixed: false,
  browserHeight: getBrowserHeight(),
  wrapperPosition: 0,
  mainOuterPosition: 0,
  mainPosition: 0,
  sideBarPosition: 0,
  windowScrollBottom: 0,
  mainOffsetTop: 0,
  readComplete: false,
  bannersIsFixed: false,
  firstLoad: true,
  isSideBarMarginChecked: false,
  sideBarMarginBottom: 0,
};

const SIDEBAR_FIXED_CLASS = 'sideBar-Container_Inner-fixed';

export default class ScrollManager extends Abstract {
  initState() {
    this.state = { ...INITIAL_STATE };

    this.getElement();

    if (this.overlayAd && this.state.firstLoad) {
      this.onUpdateState({
        firstLoad: false,
      });
    }
  }

  getElement() {
    this.rootElement = rootElement();
    this.wrapper = document.querySelector('.js-wrapper');
    this.mainOuter = document.querySelector('.js-main-outer');
    this.main = document.querySelector('.js-main');
    this.sideBar = document.querySelector('.js-side-bar-container');
    this.trigger = document.querySelector('.js-back-to-page-top');
    this.sponsoredArticleDescriptionContainer = document.querySelector('.js-sponsored-article-description-container');
    this.footer = document.querySelector('.js-global-footer');
    this.overlayAd = document.querySelector('.js-overlay-ads');
  }

  addEvent() {
    this.trigger.addEventListener('click', this.onClick.bind(this));

    window.addEventListener('scroll', this.onScroll.bind(this));
    window.addEventListener('resize', this.onResize.bind(this));

    if (this.sponsoredArticleDescriptionContainer) {
      this.onReadCompleteSponsoredArticle = this._onReadComplete.bind(this, this.sponsoredArticleDescriptionContainer, this.onSendSponsoredArticleGTag.bind(this));

      window.addEventListener('scroll', this.onReadCompleteSponsoredArticle);
    }
  }

  onClick() {
    TweenMax.to(this.rootElement, 0.3, {
      scrollTop: 0,
      ease: Power3.easeOut,
    });
  }

  onScroll() {
    if (!this.state.isSideBarMarginChecked) {
      this.setSideBarMargin();
    }

    this.onUpdateState({
      scrollTop: this.rootElement.scrollTop,
    });

    this.getPosition();

    this.onUpdateState({
      windowScrollBottom: this.state.scrollTop + this.state.browserHeight,
      mainOffsetTop: this.state.scrollTop + this.state.mainPosition.top,
    });

    if (this.state.mainPosition.height < this.state.sideBarPosition.height) {
      if (this.state.isSideBarFixed) {
        this.toggleSideBarFixed();
      }
      return;
    }

    if (this.state.windowScrollBottom > (this.state.mainOffsetTop + this.state.sideBarPosition.height + this.state.sideBarMarginBottom)) {
      if (!this.state.isSideBarFixed) this.toggleSideBarFixed();
      this.setSideBarPositionX();
      this.setSideBarPositionY();
    } else if (this.state.isSideBarFixed) {
      this.toggleSideBarFixed();
    }

    this.onChangeState();
  }

  onChangeState() {
    if (this.overlayAd) {
      this.footer.style.paddingBottom = `${this.overlayAd.clientHeight + 20}px`;
    }
  }

  onResize() {
    this.onUpdateState({
      browserHeight: getBrowserHeight(),
    });

    this.getPosition();

    if (this.state.isSideBarFixed) {
      this.setSideBarPositionX();
      this.setSideBarPositionY();
    }
  }

  getPosition() {
    this.onUpdateState({
      wrapperPosition: this.wrapper.getBoundingClientRect(),
      mainOuterPosition: this.mainOuter.getBoundingClientRect(),
      mainPosition: this.main.getBoundingClientRect(),
      sideBarPosition: this.sideBar.getBoundingClientRect(),
    });
  }

  setSideBarMargin() {
    this.getPosition();
    const sideBarMargin = document.querySelector('.js-side-bar-margin');
    const sideBarNoMargin = document.querySelector('.js-side-bar-no-margin');

    if (sideBarMargin || sideBarNoMargin) {
      if (this.state.mainPosition.height > this.state.sideBarPosition.height) {
        this.onUpdateState({
          isSideBarMarginChecked: true,
          sideBarMarginBottom: sideBarMargin ? 130 : 0,
        });
      } else {
        this.sideBar.classList.remove('sideBar-Container-marginBottom');

        this.onUpdateState({
          isSideBarMarginChecked: true,
        });
      }
    }
  }

  toggleSideBarFixed() {
    const changeState = !this.state.isSideBarFixed;

    this.onUpdateState({
      isSideBarFixed: changeState,
    });

    toggleClass(this.sideBar, this.state.isSideBarFixed, SIDEBAR_FIXED_CLASS);
  }

  setSideBarPositionX() {
    const sideBarX = Math.round(this.state.mainOuterPosition.right - this.state.sideBarPosition.width);
    this.sideBar.style.left = `${sideBarX}px`;
  }

  setSideBarPositionY() {
    if (this.state.windowScrollBottom >= (this.state.mainOffsetTop + this.state.mainPosition.height) + this.state.sideBarMarginBottom) {
      const sideBarY = Math.round(this.state.browserHeight - this.state.wrapperPosition.bottom);

      this.sideBar.style.bottom = `${sideBarY - this.state.sideBarMarginBottom}px`;
    } else {
      this.sideBar.style.bottom = 0;
    }
  }

  _onReadComplete(container, callback) {
    const thresholdElement = container;
    const thresholdPosition = thresholdElement.offsetTop + thresholdElement.clientHeight;

    if (!this.state.readComplete && this.state.windowScrollBottom > thresholdPosition) {
      this.onUpdateState({
        readComplete: true,
      });

      callback();
    }
  }

  onSendSponsoredArticleGTag() {
    onSendGTag('完読', {
      event_category: 'スポコン完読',
      event_label: '完読',
      non_interaction: true,
    });

    window.removeEventListener('scroll', this.onReadCompleteSponsoredArticle);
  }
}
