import SetCookiesPPID from './setCookiesPPID';
import ScrollManager from './scrollManager';
import ShareUtil from './shareUtil';
import SearchView from './searchView';
import ArticleDetailView from './articleDetailView';
import PersonalityQuizView from './personalityQuizView';
import SideBarView from './sideBarView';
import ToggleTextView from './toggleTextView';
import HeaderAds from './headerAds';
import ArticlePhotoView from './article/photoView';
import GameDetailView from './gameDetailView';
import GoogleAnalyticsManager from './googleAnalyticsManager';
import LoadMoreView from './loadMoreView';

const loadToggleTextViewInit = () => {
  const toggleTextView = new ToggleTextView();

  toggleTextView.init();
};

const loadMoreInit = () => {
  const loadMoreInstance = new LoadMoreView();

  loadMoreInstance.init();
};

const onReady = () => {
  const setCookiesPPIDInstance = new SetCookiesPPID();
  const shareUtilInstance = new ShareUtil();
  const searchInstance = new SearchView();
  const personalityQuizInstance = new PersonalityQuizView();
  const articleImageView = new ArticlePhotoView();
  const gameDetailView = new GameDetailView();
  const googleAnalyticsManager = new GoogleAnalyticsManager();

  setCookiesPPIDInstance.init();
  shareUtilInstance.init();
  searchInstance.init();
  personalityQuizInstance.init();
  articleImageView.init();
  gameDetailView.init();
  googleAnalyticsManager.init();

  const isArticlePage = document.querySelector('.js-article');

  if (isArticlePage) {
    const articleDetailInstance = new ArticleDetailView();

    articleDetailInstance.init();
  }

  const hasSideBar = document.querySelector('.js-side-bar-container');

  if (hasSideBar) {
    const scrollManagerInstance = new ScrollManager();
    const sideBarInstance = new SideBarView();

    scrollManagerInstance.init();
    sideBarInstance.init();
  }

  const isToggleTextPage = document.querySelector('.js-toggle-text-button');
  if (isToggleTextPage) loadToggleTextViewInit();

  const isLoadMorePage = document.querySelector('.js-load-more-container');
  if (isLoadMorePage) loadMoreInit();

  HeaderAds();
};

document.addEventListener('DOMContentLoaded', () => {
  onReady();
});
