/* global Swiper */
import Abstract from '../abstract';
import { onSendGTag } from '../module';

const swiperInit = (parentEl, options) => {
  const container = parentEl.querySelector('.swiper-container');

  return new Swiper(container, options);
};

const onSendClickBackToArticleButtonLinkGTag = (event) => {
  onSendGTag('タップ', {
    event_category: '画像ページ記事に戻る',
    event_label: 'タップ',
    link_url: event.currentTarget.getAttribute('href'),
  });
};

const onSendClickArticleRankingLinkGTag = (event, index) => {
  onSendGTag('タップ', {
    event_category: '画像ページランキング',
    event_label: index,
    link_url: event.currentTarget.getAttribute('href'),
  });
};

export default class ArticlePhotoView extends Abstract {
  init() {
    this.getElement();

    this.initSwiper();

    this.addEvent();
  }

  getElement() {
    this.currentImagePerNumberOfPhotos = document.querySelector(
      '.js-article-photo-current-image-per-number-of-images',
    );
    this.articlePhotoCarouselContainer = document.querySelector('.js-article-photo-image-carousel-container');
    this.articlePhotoPreviousButtonLink = document.querySelector('.js-article-photo-image-container-pre-button');
    this.articlePhotoBackButtonLinks = document.querySelectorAll('.js-article-photo-image-container-back-button');
    this.articlePhotoNextButtonLink = document.querySelector('.js-article-photo-image-container-next-button');
    this.articlePhotos = document.querySelector('.js-article-photo-images-list');
    this.articlePhotoRankingLinks = document.querySelectorAll('.js-article-photo-ranking-link');
  }

  updateCurrentImagePerNumberOfPhotosContent(currentIndex, totalItems) {
    if (!this.currentImagePerNumberOfPhotos) {
      return;
    }

    this.currentImagePerNumberOfPhotos.innerHTML = `${currentIndex}/${totalItems}`;
  }

  updateArticlePhotoImageContainerLink(currentArticlePhotoId) {
    if (!this.articlePhotos) {
      return;
    }

    let currentArticlePhotoIndex;
    const articlePhotoImages = this.articlePhotos.querySelectorAll('.js-article-photo-images-item');
    articlePhotoImages.forEach((imageElement, imageElementIndex) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        currentArticlePhotoIndex = imageElementIndex;
      }
    });

    const { pathname } = window.location;
    const [, previousPhotoId] = pathname.match(/(\d+)/gm);
    const articlePhotoImagesSize = articlePhotoImages.length;

    if (this.articlePhotoPreviousButtonLink) {
      const previousArticlePhotoIndex = currentArticlePhotoIndex === 0
        ? articlePhotoImagesSize - 1
        : currentArticlePhotoIndex - 1;
      const previousArticlePhotoElement = articlePhotoImages[previousArticlePhotoIndex];
      const previousPathname = pathname.replace(
        `photos/${previousPhotoId}`,
        `photos/${previousArticlePhotoElement.dataset.id}`,
      );
      this.articlePhotoPreviousButtonLink.dataset.id = previousArticlePhotoElement.dataset.id;
      this.articlePhotoPreviousButtonLink.href = previousPathname;
    }

    if (this.articlePhotoNextButtonLink) {
      const nextArticlePhotoIndex = currentArticlePhotoIndex === articlePhotoImagesSize - 1
        ? 0
        : currentArticlePhotoIndex + 1;
      const nextArticlePhotoElement = articlePhotoImages[nextArticlePhotoIndex];
      const nextPathname = pathname.replace(
        `photos/${previousPhotoId}`,
        `photos/${nextArticlePhotoElement.dataset.id}`,
      );
      this.articlePhotoNextButtonLink.dataset.id = nextArticlePhotoElement.dataset.id;
      this.articlePhotoNextButtonLink.href = nextPathname;
    }
  }

  updateArticlePhotoItemStyle(currentArticlePhotoId) {
    if (!this.articlePhotos) {
      return;
    }

    this.articlePhotos.querySelectorAll('.js-article-photo-images-item').forEach((imageElement) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        imageElement.classList.add('article-photo-Images_Item-Current');
        return;
      }

      imageElement.classList.remove('article-photo-Images_Item-Current');
    });
  }

  initSwiper() {
    if (!this.articlePhotoCarouselContainer) {
      return;
    }

    let currentArticlePhotoId = this.articlePhotoCarouselContainer.dataset.currentImageId;
    const articlePhotoCarousels = this.articlePhotoCarouselContainer.querySelectorAll(
      '.js-article-photo-image-carousel-slide',
    );
    let currentArticlePhotoCarouselIndex = 0;

    articlePhotoCarousels.forEach((imageElement, imageElementIndex) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        currentArticlePhotoCarouselIndex = imageElementIndex;
      }
    });

    this.articlePhotoCarouselContainerSwiper = swiperInit(this.articlePhotoCarouselContainer, {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      initialSlide: currentArticlePhotoCarouselIndex,
      navigation: {
        prevEl: '.js-article-photo-image-carousel-navigation-prev',
        nextEl: '.js-article-photo-image-carousel-navigation-next',
      },
      on: {
        init: () => {
          this.articlePhotoCarouselContainer.classList.add('article-photo-ImageCarousel_Container-visible');
          this.updateCurrentImagePerNumberOfPhotosContent(
            currentArticlePhotoCarouselIndex + 1,
            articlePhotoCarousels.length,
          );
          this.updateArticlePhotoImageContainerLink(currentArticlePhotoId);
          this.updateArticlePhotoItemStyle(currentArticlePhotoId);
        },
        slideChange: () => {
          if (!this.articlePhotoCarouselContainerSwiper) {
            return;
          }

          const { loopedSlides, realIndex, slides } = this.articlePhotoCarouselContainerSwiper;
          const currentArticlePhotoElement = slides[loopedSlides + realIndex];

          currentArticlePhotoId = currentArticlePhotoElement.dataset.id;
          this.updateCurrentImagePerNumberOfPhotosContent(realIndex + 1, loopedSlides);
          this.updateArticlePhotoImageContainerLink(currentArticlePhotoId);
          this.updateArticlePhotoItemStyle(currentArticlePhotoId);

          const { pathname } = window.location;
          const [articleId, previousPhotoId] = pathname.match(/(\d+)/gm);
          if (currentArticlePhotoId !== previousPhotoId) {
            const currentPathname = pathname.replace(`photos/${previousPhotoId}`, `photos/${currentArticlePhotoId}`);

            // onSendClickArticlePhotoItemGTag(currentPathname);
            window.history.pushState({
              articleId,
              previousPhotoId,
            }, '', currentPathname);
          }
        },
      },
    });
  }

  updateCurrentArticlePhotoCarouse(currentArticlePhotoId) {
    if (!this.articlePhotoCarouselContainerSwiper) {
      return;
    }

    const { loopedSlides, slides } = this.articlePhotoCarouselContainerSwiper;
    Object.values(slides).forEach((slide, slideIndex) => {
      if (
        slideIndex >= loopedSlides && slideIndex < loopedSlides * 2
        && slide?.dataset?.id === currentArticlePhotoId
      ) {
        this.articlePhotoCarouselContainerSwiper.slideTo(slideIndex);
      }
    });
  }

  addEvent() {
    if (this.articlePhotos) {
      this.articlePhotos.querySelectorAll('.js-article-photo-images-item').forEach((imageElement) => {
        imageElement.addEventListener('click', (event) => {
          const currentArticlePhotoId = event.currentTarget.dataset.id;
          this.updateCurrentArticlePhotoCarouse(currentArticlePhotoId);
        });
      });
    }

    if (this.articlePhotoPreviousButtonLink) {
      this.articlePhotoPreviousButtonLink.addEventListener('click', (event) => {
        event.preventDefault();

        const previousSwiperButton = document.querySelector('.js-article-photo-image-carousel-navigation-prev');
        if (previousSwiperButton) {
          previousSwiperButton.click();
          return;
        }

        const currentArticlePhotoId = event.currentTarget.dataset.id;
        this.updateCurrentArticlePhotoCarouse(currentArticlePhotoId);
      });
    }

    if (this.articlePhotoBackButtonLinks) {
      this.articlePhotoBackButtonLinks.forEach((element) => {
        element.addEventListener('click', onSendClickBackToArticleButtonLinkGTag);
      });
    }

    if (this.articlePhotoNextButtonLink) {
      this.articlePhotoNextButtonLink.addEventListener('click', (event) => {
        event.preventDefault();

        const nextSwiperButton = document.querySelector('.js-article-photo-image-carousel-navigation-next');
        if (nextSwiperButton) {
          nextSwiperButton.click();
          return;
        }

        const currentArticlePhotoId = event.currentTarget.dataset.id;
        this.updateCurrentArticlePhotoCarouse(currentArticlePhotoId);
      });
    }

    this.articlePhotoRankingLinks.forEach((element, index) => {
      element.addEventListener('click', (event) => onSendClickArticleRankingLinkGTag(event, index + 1));
    });

    window.addEventListener('popstate', (event) => {
      const { pathname } = event.currentTarget.location;
      const [, photoId] = pathname.match(/(\d+)/gm);
      this.updateCurrentArticlePhotoCarouse(photoId);
    });
  }
}
