import Abstract from './abstract';
import { onSendGTag } from './module';

const onSendCategoryMenuGTag = (e) => {
  const menuName = e.currentTarget.dataset.gaMenuName;

  onSendGTag('タップ', {
    event_category: 'サイドバー > カテゴリ一覧',
    event_label: menuName,
  });
};

const onSendAffiliateLinkGTag = (e) => {
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: 'サイドバー > アフィリエイトリンク',
    event_label: 'タップ',
    link_url: link,
  });
};

export default class SideBarView extends Abstract {
  getElement() {
    this.menuLinks = Array.prototype.slice.call(document.querySelectorAll('.js-side-bar-menu-link'));
    this.AffiliateLink = document.querySelector('.js-side-bar-affiliate-link');
  }

  addEvent() {
    this.menuLinks.forEach((el) => {
      el.addEventListener('click', onSendCategoryMenuGTag);
    });

    if (this.AffiliateLink) this.AffiliateLink.addEventListener('click', onSendAffiliateLinkGTag);
  }
}
