import Abstract from './abstract';
import { onSendGTag } from './module';

const INITIAL_STATE = {
  resultPoint: 0,
  questionLength: 0,
  currentQuestionIndex: 0,
  headerIsVisible: true,
  isSelected: false,
};

const STEP_HIDDEN_CLASS = 'personalityQuiz-Question_Step-hidden';

export default class PersonalityQuiView extends Abstract {
  init() {
    this.getElement();

    this.onUpdateState({
      questionLength: this.questions.length,
    });

    this.addEvent();
  }

  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.header = document.querySelector('.js-personality-quiz-header');
    this.questionContainer = document.querySelector('.js-personality-quiz-question-container');
    this.questions = Array.prototype.slice.call(document.querySelectorAll('.js-personality-quiz-question'));
    this.answerButtons = Array.prototype.slice.call(document.querySelectorAll('.js-personality-quiz-answer-button'));
  }

  addEvent() {
    this.answerButtons.forEach((el) => {
      el.addEventListener('click', this.onClick.bind(this, el), {
        once: true,
      });
    });
  }

  onClick(currentTargetButton) {
    if (this.state.isSelected) return;

    this.onUpdateState({
      isSelected: true,
    });

    let currentPoint = Number(this.state.resultPoint);
    currentPoint += Number(currentTargetButton.dataset.point);

    this.onUpdateState({
      resultPoint: currentPoint,
    });

    if (this.state.currentQuestionIndex < this.state.questionLength - 1) {
      this.goToNextQuestion();
      this.onSendPageViewGTag();
    } else {
      this.goToResult();
    }

    if (this.state.headerIsVisible) {
      this.header.remove();

      this.onUpdateState({
        headerIsVisible: false,
      });
    }
  }

  onSendPageViewGTag() {
    const { gaPageType } = this.questions[this.state.currentQuestionIndex].dataset;
    const gaParameter = {
      page_type: gaPageType,
    };

    onSendGTag('page_view', gaParameter);
  }

  goToNextQuestion() {
    this.questions[this.state.currentQuestionIndex].classList.add(STEP_HIDDEN_CLASS);

    const nextQuestionIndex = this.state.currentQuestionIndex + 1;

    this.onUpdateState({
      currentQuestionIndex: nextQuestionIndex,
    });

    this.questions[this.state.currentQuestionIndex].classList.remove(STEP_HIDDEN_CLASS);

    this.onUpdateState({
      isSelected: false,
    });
  }

  goToResult() {
    if (!/\.html$/.test(window.location.href)) { // eslint-disable-line no-lonely-if
      window.location = `${location.pathname}/results/${this.state.resultPoint}${location.search}`;
    }
  }
}
