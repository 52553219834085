const INITIAL_STATE = {

};

export default class Abstract {
  constructor() {
    this.initState();
  }

  init() {
    this.getElement();
    this.addEvent();
  }

  initState() {
    this.state = { ...INITIAL_STATE };
  }

  onUpdateState(entity) {
    Object.assign(this.state, entity);
  }

  getElement() {

  }

  addEvent() {

  }
}
