export default function() {
  const header = document.querySelector('.header-HeaderGAM');
  if (!header) {
    return
  }
  const closeButton = header.querySelector('.header-HeaderGAM .icon-close');

  if (!closeButton) {
    return
  }

  closeButton.addEventListener('click', function() {
    header.remove();
  })
}
