/* global $, Handlebars */

import Abstract from './abstract';

const INITIAL_STATE = {
  isRequested: false,
};

export default class LoadMoreView extends Abstract {
  constructor() {
    super();

    this.setTemplate();
    this.handleLoadMoreButtonClick = this.handleLoadMoreButtonClick.bind(this);
  }

  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.loadMoreButtons = document.querySelectorAll('.js-load-more-button');
    this.loadMoreContainers = document.querySelectorAll('.js-load-more-container');
  }

  addEvent() {
    this.loadMoreButtons.forEach((element, index) => element.addEventListener('click', (event) => {
      event.preventDefault();
      this.handleLoadMoreButtonClick(element, this.loadMoreContainers[index]);
    }));
  }

  handleLoadMoreButtonClick(button, container) {
    if (this.state.isRequested) return;

    this.onUpdateState({
      isRequested: true,
    });

    const {
      templateType, location, nextCursor, nextCursorOrder, nextCursorPublishDatetime,
    } = button.dataset;
    const requestBody = {
      location,
      start_cursor: nextCursor,
      start_cursor_order: nextCursorOrder,
      start_cursor_publish_datetime: nextCursorPublishDatetime,
    };

    $.ajax({
      // The game's data will be retrieved from the game list page
      url: window.location.href.replace(/games\/\d+/g, 'games'),
      data: requestBody,
      dataType: 'json',
      success: (response) => {
        const {
          items,
          next_cursor: newNextCursor,
          next_cursor_order: newNextCursorOrder,
          next_cursor_publish_datetime: newCursorPublishDatetime,
        } = response;
        const newItems = Handlebars.compile(this[`${templateType}Template`])({ items });

        if (container) {
          $(container).append(newItems);
        }

        if (newNextCursor) {
          button.setAttribute('data-next-cursor', newNextCursor);
        }

        if (newNextCursorOrder) {
          button.setAttribute('data-next-cursor-order', newNextCursorOrder);
        }

        if (newCursorPublishDatetime) {
          button.setAttribute('data-next-cursor-publish-datetime', newCursorPublishDatetime);
        }

        if (!(newNextCursor || newNextCursorOrder || newCursorPublishDatetime)) {
          button.remove();
        }

        this.onUpdateState({
          isRequested: false,
        });
      },
    });
  }

  setTemplate() {
    this.popularGameTemplate = `
      {{#each items}}
        <div class="games-PopularGamesContainer_Content_List_Item">
          <a class="games-PopularGamesContainer_Content_List_Item_Game js-google-analytics-item"
            href="games/{{id}}" data-ga-event-category="無料の人気ゲーム" data-ga-event-label="{{title}}"
          >
            <div
              class="games-PopularGamesContainer_Content_List_Item_Game_Thumbnail"
              style="background-image: url('{{thumbnail.image.url}}')"
            ></div>
            <div class="games-PopularGamesContainer_Content_List_Item_Game_Title">{{title}}</div>
          </a>
          <div class="games-PopularGamesContainer_Content_List_Item_Company">
            <div class="games-PopularGamesContainer_Content_List_Item_Company_Name">{{display_information}}</div>
          </div>
        </div>
      {{/each}}`;

    this.recommendedGameTemplate = `
      {{#each items}}
        <li class="games-RecommendedGamesContainer_Content_List_Item">
          <a
            class="games-RecommendedGamesContainer_Content_List_Item_Link js-google-analytics-item"
            href="games/{{id}}" data-ga-event-category="あなたにおすすめのゲーム" data-ga-event-label="{{title}}"
          >
            <div
              class="games-RecommendedGamesContainer_Content_List_Item_Thumbnail"
              style="background-image: url('{{thumbnail.image.url}}')"
            ></div>
            <div class="games-RecommendedGamesContainer_Content_List_Item_Information">
              <div class="games-RecommendedGamesContainer_Content_List_Item_Title">{{title}}</div>
              <p class="games-RecommendedGamesContainer_Content_List_Item_Company">{{display_information}}</p>
            </div>
          </a>
        </li>
      {{/each}}`;
  }
}
