/* eslint-disable class-methods-use-this */
import { v4 as uuidv4 } from 'uuid';

const getCookie = (cookieName) => {
  const cookie = {};

  document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=');

    cookie[key.trim()] = value;
  });

  return cookie[cookieName];
};

export default class SetCookiesPPID {
  init() {
    const now = new Date();
    const expireTime = now.getTime() + 1000 * 60 * 60 * 24 * 180; // Expire time is 180 days
    now.setTime(expireTime);
    let ppid = getCookie('ppid');

    if (!ppid) {
      ppid = uuidv4();
    }

    /**
     * When the user accesses the website:
     * 1. if the ppid cookie exists, we keep the current PPID
     * 2. otherwise the ppid cookie will be set as the newly generated PPID.
     * 3. within 180 days, the expiration date will be reset to the following 180 days
     */
    document.cookie = `ppid=${ppid};expires=${now.toUTCString()};path=/`;
  }
}
