/* global gtag */

const toggleClass = (el, state, classname) => {
  el.classList[state ? 'add' : 'remove'](classname);
};

const getBrowserHeight = () => document.documentElement.clientHeight;

const rootElement = () => document.scrollingElement || document.documentElement || document.body;

const onSendGTag = (action, params) => {
  if (typeof gtag === 'undefined') return;

  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'sendGTag',
    eventAction: action,
    eventParams: params,
  });

  gtag('event', action, params);
};

export {
  toggleClass, getBrowserHeight, rootElement, onSendGTag,
};
