import Abstract from './abstract';
import { toggleClass } from '../mobile/module';

const INITIAL_STATE = {
  searchKeyword: '',
};

const DELETE_HIDDEN_CLASS = 'header-SearchForm_Delete-hidden';

export default class SearchView extends Abstract {
  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.container = document.querySelector('.js-searchForm');
    this.input = this.container.querySelector('.js-searchForm-input');
    this.delete = this.container.querySelector('.js-searchForm-delete');
    this.searchButton = this.container.querySelector('.js-searchForm-searchButton');
  }

  addEvent() {
    this.input.addEventListener('input', this.onInput.bind(this));
    this.input.addEventListener('keypress', this.onKeypress.bind(this));
    this.delete.addEventListener('click', this.onDeleteClick.bind(this));
    this.searchButton.addEventListener('click', this.onSearchButtonClick.bind(this));
  }

  onInput() {
    this.onUpdateState({
      searchKeyword: this.input.value,
    });

    toggleClass(this.delete, !this.state.searchKeyword.length, DELETE_HIDDEN_CLASS);
  }

  onKeypress(event) {
    if (event.keyCode === 13) {
      this.navigateToSearchPage();
    }
  }

  onSearchButtonClick() {
    this.navigateToSearchPage();
  }

  navigateToSearchPage() {
    if (!this.state.searchKeyword.length) {
      return;
    }

    const searchKeyword = encodeURIComponent(this.state.searchKeyword);

    location.href = `/search?keyword=${searchKeyword}`;
  }

  onDeleteClick() {
    this.input.value = '';

    this.onInput();
  }
}
