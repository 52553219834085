import Abstract from './abstract';

export default class ToggleTextView extends Abstract {
  init() {
    this.getElement();

    if (this.toggleButton && this.container) {
      this.setDefaultData();
      this.addEvent();
    }
  }

  getElement() {
    this.toggleButton = document.querySelector('.js-toggle-text-button');

    const { containerClassName } = this.toggleButton.dataset;
    this.container = document.querySelector(`.${containerClassName}`);
  }

  setDefaultData() {
    const { containerClassName, containerMaxHeight } = this.toggleButton.dataset;
    const isShowingMore = this.container.clientHeight > containerMaxHeight;

    if (isShowingMore) {
      this.container.classList.add(`${containerClassName}--ShowLess`);
      this.toggleButton.innerHTML = 'もっと見る <i class="icon-arrow-downward-small"></i>';
      this.toggleButton.style.display = 'block';
    } else {
      this.container.classList.add(`${containerClassName}--ShowMore`);
      this.toggleButton.style.display = 'none';
    }
  }

  addEvent() {
    this.toggleButton.addEventListener('click', this.onClick.bind(this));
  }

  onClick() {
    const { containerClassName, containerMaxHeight } = this.toggleButton.dataset;
    const isShowingMore = this.container.clientHeight > containerMaxHeight;

    if (isShowingMore) {
      this.container.classList.remove(`${containerClassName}--ShowMore`);
      this.container.classList.add(`${containerClassName}--ShowLess`);
      this.toggleButton.innerHTML = 'もっと見る <i class="icon-arrow-downward-small"></i>';
    } else {
      this.container.classList.remove(`${containerClassName}--ShowLess`);
      this.container.classList.add(`${containerClassName}--ShowMore`);
      this.toggleButton.innerHTML = '隠す <i class="icon-arrow-upward-small"></i>';
    }
  }
}
